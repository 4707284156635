import { DtoBlock, EnumsBlockType } from '@lp-lib/api-service-client/public';
import { assertDefinedFatal } from './asserts';
import { Block, BlockType } from './block';

// Part A: Ensure the value of the manual definition and generated definition
// stay synced. Note that this is a separate file from block.ts to avoid
// exacerbating any circular import issues, since this is being executing during
// module init!
const genBlockTypeValues = Object.values(EnumsBlockType);
const manualBlockTypeValues = Object.values(BlockType);
for (const e of genBlockTypeValues) {
  const found = manualBlockTypeValues.find(
    (value) => e && value === e.toString()
  );
  assertDefinedFatal(found);
}

export function fromAPIBlockType(e: EnumsBlockType): BlockType {
  // Part B: so it is safe to force a type hint
  return e as unknown as BlockType;
}

/**
 * Use this when you know the specific type of block you have, to get a specific
 * type back:
 * - `fromAPIBlock(b, BlockType.SPOTLIGHT)`
 */
export function fromAPIBlock<T extends BlockType>(
  block: DtoBlock,
  kind: T
): Extract<Block, { type: T }> {
  const t = fromAPIBlockType(block.type);
  if (t !== kind) throw new Error(`InvalidTypeConversion: block kind mismatch`);
  return block as unknown as Extract<Block, { type: T }>;
}

/**
 * Use this when you don't know the specific type of block you have, but still
 * want to "convert" it the hand-written types to use in something like
 * BlockKnifeUtils:
 * - `fromAPIBlock(b, BlockType)`
 */
export function fromAPIBlockTypes(
  block: DtoBlock
): Extract<Block, { type: BlockType }> {
  // Part C: This is "safe" because we manually verify that the generated enum
  // values match the hand-written enum values above.
  return block as unknown as Block;
}
